import { useRef } from "react";

export const useRecaptcha = (ref) => {

  // 提供一個重置 reCAPTCHA 的方法
  const resetRecaptcha = () => {
    if (ref.current) {
      ref.current.reset();  // 調用 reCAPTCHA 的 reset() 方法
    }
  };

  return {resetRecaptcha };
};