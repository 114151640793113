export const LightBoxEnum = Object.freeze({
  EmailOtpCode: 1,
  EmailResend: 2,
  EmailOtpCodeExpired: 3,
  EmailVerified: 4,
  EmailResendfromExpired: 5,
  PasswordNotChanged:6,
  EnterNewPassword:7
});

export const ErrorEnum = Object.freeze({
  EmailNotVerify: 99,
});
