import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import * as utils from "../helpers/utilityHelpers";
import * as validationSchemas from "../validation/validationSchemas";
import { useParams, useLocation, Link } from "react-router-dom";
import ActiveLightbox from "../components/ActiveLightbox";
import MediaBtn from "components/MediaBtn";
import { LightBoxEnum, ErrorEnum } from "components/Enum";
import { TranslationContext } from "../contexts/TranslationProvider";
import { usePWshow } from "../hooks/useCustom";

import useLightbox from "../hooks/useLightbox";
import { useLoading } from "../contexts/LoadingProvider";
import { useFormik } from "formik";

function Login() {
  const locale = useParams().locale;
  const location = useLocation();
  const currentSearch = location.search;
  const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();
  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);
  const [Headline, setHeadline] = useState("");
  const [forgotPasswordLinkText, setForgotPasswordLinkText] = useState("");
  const [privacyPolicyLinkText, setPrivacyPolicyLinkText] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRemeberMe, setIsRemeberMe] = useState(false);
  const [codeChallenge, setCodeChallenge] = useState("");
  const [state, setState] = useState("");
  const triggerButtonRef = useRef(null);
  
  const formik = useFormik({
    initialValues: {
      Account: "",
      Password: "",
    },
    validationSchema: validationSchemas.signInValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMsg("");
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/sign-in`;
        const res = await utils.fetchApi(url, "POST", values);
        if (res.success) {
          if(res.pwdIsExpired)
          {
            setIsLoading(false);
            openLightbox(LightBoxEnum.PasswordNotChanged);
            return;
          }
          setIsLoggedIn(true);
          localStorage.locale = "";
          window.location.href = res.redirectUrl;
          handleRemeberMe();
        } else {
          setIsLoading(false);
          if (res.statusCode === ErrorEnum.EmailNotVerify) {
            openLightbox(LightBoxEnum.EmailOtpCode);
          } else {
            setErrorMsg(res.errorMsg);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const queryParams = new URLSearchParams(location.search);
      const state = utils.getQueryParamIgnoreCase(queryParams, "state") ?? "";
      const clientId = utils.getQueryParamIgnoreCase(queryParams,"clientid") ?? "";
      const redirectUrl = utils.getQueryParamIgnoreCase(queryParams,"redirecturl") ? 
                        utils.getQueryParamIgnoreCase(queryParams,"redirecturl") : (utils.getQueryParamIgnoreCase(queryParams,"redirect_uri") ?? "");
      const referrerUrl =
        document.referrer.indexOf("account") > -1 ? "" : document.referrer;
      const url = `${process.env.REACT_APP_URL}/${locale}/api/account/sign-in?clientId=${clientId}&RedirectUrl=${redirectUrl}&refererUrl=${referrerUrl}&state=${state}`;
      const res = await utils.fetchApi(url);

      if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
      }

      if (localStorage.Account) {
        setIsRemeberMe(true);
        formik.setFieldValue('Account', localStorage.Account);
      }

      setHeadline(res.headline);
      setForgotPasswordLinkText(res.forgotPasswordLinkText);
      setPrivacyPolicyLinkText(res.privacyPolicyLinkText);
      setCodeChallenge(res.codeChallenge);
      setState(res.state);
      setIsLoading(false);
    };

    fetchData();
  }, [locale]);

  useEffect(() => {
    if (isRemeberMe) {
      console.log(isRemeberMe);
    }
  }, [isRemeberMe]);

  const handleRemeberMe = () => {
    const checkbox = document.getElementById("rememberMeCheckbox");
    if (checkbox.checked) {
      localStorage.Account = formik.values.Account;
      setIsRemeberMe(true);
    } else {
      localStorage.Account = "";
      setIsRemeberMe(false);
    }
  };

  return (
    <Fragment>
      <div className="agw-mb-5">
        <h1 className="agw-fs-headline-l agw-text-center">
          {Headline}
        </h1>
      </div>
      <div className="agw-mb-5 agw-col-md-5 agw-mx-auto">
        <p className="agw-fs-title-s agw-mb-3">
          {translations.SignInDescription}
        </p>
        <p className="agw-fs-title-s agw-mb-0">
          {translations["noAcerID?"]}{" "}
          <Link
            className="agw-fw-semibold agw-link-acer"
            to={`/${locale}/account/sign-up${currentSearch}`}
          >
            {translations.SignUp}
          </Link>
        </p>
      </div>

      <form className="agw-col-md-5 agw-mx-auto" onSubmit={formik.handleSubmit}>
        <div className="agw-row agw-gy-3">
          <div className="agw-col-12">
            <label htmlFor="Account"
              className="agw-form-label agw-fw-semibold"
              aria-label={translations.emailAddressRegister}
            >
              {translations.emailAddressRegister}
            </label>
            <input
              type="email"
              className={`agw-form-control ${formik.touched.Account && formik.errors.Account ? "agw-border-negative" : ""}`}
              id="Account"
              name="Account"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Account}
              aria-required="true"
              aria-invalid={formik.touched.Account && formik.errors.Account ? true : false}
              aria-describedby={formik.touched.Account && formik.errors.Account ? 'emailError' : ''}
            />
            {formik.touched.Account && formik.errors.Account ? (
              <span
                dangerouslySetInnerHTML={{ __html: formik.errors.Account }}
                className="agw-d-block agw-mt-1 agw-text-negative"
                id="emailError"
                aria-live="assertive" aria-atomic="true"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label
              htmlFor="Password"
              className="agw-form-label agw-fw-semibold"
              aria-label={translations.passwordRegister}
            >
              {translations.passwordRegister}
            </label>
            <div className="agw-po-relative">
              <input
                type={isPwShow ? "text" : "password"}
                className={`agw-form-control ${formik.touched.Password && formik.errors.Password ? "agw-border-negative" : ""}`}
                id="Password"
                name="Password"
                value={formik.values.Password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-required="true"
                aria-invalid={formik.touched.Password && formik.errors.Password ? true : false}
                aria-describedby={formik.touched.Password && formik.errors.Password ? 'passwordError' : ''}
              />
              <button
                className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                style={{ transform: "translate(-100%,-50%)" }}
                type="button"
                onClick={(e) => handlePwShow(e)}
                aria-label={`${isPwShow ? translations.showPassword : translations.hidePassword}`}
              >
                <svg
                  id="icon-visibility-off"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}
                  role="img"
                >
                  <title>{translations.hidePassword = 'undefined' ? 'Hide Password' : translations.hidePassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-off"
                    d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                  />
                </svg>
                <svg
                  id="icon-visibility-on"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}
                  role="img"
                >
                  <title>{translations.showPassword = 'undefined' ? 'Show Password' : translations.showPassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-on"
                    d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                  />
                </svg>
              </button>
            </div>
            {formik.touched.Password && formik.errors.Password ? (
              <span
                dangerouslySetInnerHTML={{ __html: formik.errors.Password }}
                className="agw-d-block agw-mt-1 agw-text-negative"
                id="passwordError"
                aria-live="assertive" aria-atomic="true"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-mb-3">
            <div className="agw-row agw-flex-column agw-flex-md-row agw-justify-content-between agw-gy-3">
              <div className="agw-col-auto">
                <div className="agw-form-check">
                  <input
                    type="checkbox"
                    className="agw-form-check-input"
                    id="rememberMeCheckbox"
                    checked={isRemeberMe}
                    onChange={() => setIsRemeberMe(!isRemeberMe)}
                  />
                  <label
                    className="agw-form-check-label"
                    htmlFor="rememberMeCheckbox"
                  >
                    {translations.rememberMe}
                  </label>
                </div>
              </div>
              <div className="agw-col-auto">
                <Link
                  className="agw-fs-body-l agw-text-decoration-underline agw-d-inline-block"
                  to={`/${locale}/account/reset-password${currentSearch}`}
                >
                  {forgotPasswordLinkText}
                </Link>
              </div>
            </div>
          </div>
          <div className="agw-col-12">
            {isLoggedIn ? (
              ""
            ) : (
              <p className="agw-text-negative agw-text-center"
                id="errorMsg"
                aria-live="assertive"
                aria-atomic="true"
              >
                {errorMsg}
              </p>
            )}
            <button
              type="submit"
              className="agw-btn agw-btn-block agw-btn-primary"
              disabled={formik.isSubmitting || !formik.values.Account}
              ref={triggerButtonRef}
              aria-describedby="errorMsg"
            >
              {translations.SignIn}
            </button>
            <div
              className="agw-form-text"
              dangerouslySetInnerHTML={{
                __html: utils.replaceStrToNum(privacyPolicyLinkText, locale),
              }}
            ></div>
          </div>
        </div>
      </form>

      <div className="agw-d-flex agw-justify-content-center agw-align-items-center agw-po-relative">
        <hr className="agw-po-absolute agw-w-100" style={{ zIndex: -1 }} />
        <span className="agw-my-5 agw-px-3 agw-bg-white agw-text-600 agw-fs-body-l">
          {translations.orSignIn}
        </span>
      </div>

      <MediaBtn state={state} codeChallenge={codeChallenge} />

      <ActiveLightbox
        activeLightbox={activeLightbox}
        email={formik.values.Account}
        password={formik.values.Password}
        onClose={closeAllLightboxes}
        onOpenLightbox={openLightbox}
        triggerButtonRef={triggerButtonRef}
        submitFormVal = {formik.values}
        onSubmit={formik.handleSubmit}
      />
    </Fragment>
  );
}

export default Login;
