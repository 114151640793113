import { useState } from "react";

export function usePWshow(initialState = false) {
  const [isPwShow, setisPwShow] = useState(initialState);
  const [isConfirmPwShow, setisConfirmPwShow] = useState(initialState);
  const handlePwShow = (e) => {
    e.preventDefault();
    setisPwShow(!isPwShow);
  };
  const handleConfirmPwShow = (e) => {
    e.preventDefault();
    setisConfirmPwShow(!isConfirmPwShow);
  };

  return [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow];
};