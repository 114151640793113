import React from "react";
import Router from './routers';
import 'main.css';

function App() {
  return (
    <Router />
  );
}

export default App;