import React from 'react';
import { useLoading } from '../contexts/LoadingProvider';
import 'styles/loading.css';

const Loading = ({ minHeight }) => {
  const { isLoading, setIsLoading } = useLoading();

  return (
    <div className={`loading-wrapper ${isLoading ? 'agw-d-block' : 'agw-d-none'}`} style={{ zIndex: 1061 }}>
      <div className="loading" style={{ minHeight }}>
        <div className="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}

export default Loading;