import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jsonData from "../data/language.json";
import { usePrevLink } from "../contexts/PrevLinkProvider";

const Languages = () => {
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const { prevLink, setPrevLink } = usePrevLink();

  useEffect(() => {
    setTitle(jsonData.LocationLanguageSelector.title);
    setData(
      [...jsonData.LocationLanguageSelector.region].sort((a, b) =>
        a.region > b.region ? 1 : -1
      )
    );
  }, []);

  return (
    <section className="section section--primary">
      <header className="section__header section__header--primary section__header--75">
        <h1 className="section__title h2">{title}</h1>
      </header>
      <div className="section__body">
        {data && (
          <div className="language-selector">
            {data.map((region) => {
              const regionData = [...region.locationLanguage].sort((a, b) =>
                a.location > b.location ? 1 : -1
              );

              return (
                <div className="language-selector__region" key={region.region}>
                  <h3 className="language-selector__title">{region.region}</h3>
                  <div className="language-selector__body">
                    <ul className="language-selector__list">
                      {regionData.map((loc, i) => {
                        return (
                          <li
                            className="language-selector__item"
                            key={loc.location + "-" + loc.language.linkText}
                          >
                            <h6 className="language-selector__heading">
                              {loc.location}
                            </h6>
                            <span> - </span>
                            <Link
                              className="language-selector__link"
                              to={`${loc.language.externalLink}account/${prevLink}`}
                              rel="nofollow"
                            >
                              {loc.language.linkText}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default Languages;
