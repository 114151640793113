import React, { Fragment, useState, useContext, useEffect, createRef, useRef } from "react";
import { TranslationContext } from "../../contexts/TranslationProvider";
import CountdownTimer from "../Countdown";
import { useParams } from "react-router-dom";
import { useLoading } from "../../contexts/LoadingProvider";
import * as utils from "../../helpers/utilityHelpers";
import { LightBoxEnum } from "../Enum";
import "styles/lightbox.css";
import useFocusTrap from '../../hooks/useFocusTrap';
import useFocusManager from '../../hooks/useFocusManager';

// Otp驗證
export const VerifyOtpEmail = ({
  isOpen,
  isResend,
  autoSend,
  email,
  onClose,
  onOpenLightbox,
  triggerButtonRef
}) => {
  const translations = useContext(TranslationContext);
  const { locale } = useParams();
  const { isLoading, setIsLoading } = useLoading();
  const [clearTimer, setClearTimer] = useState(true);
  const [seconds, countdown] = CountdownTimer({
    initialSeconds: 300,
    clearTimer,
  });
  const [resendSeconds, resendCountdown] = CountdownTimer({
    initialSeconds: 60,
    clearTimer,
  });
  const [code, setCode] = useState(Array(6).fill(""));
  const codeRefs = Array(6)
    .fill()
    .map(() => createRef());
  const [errorMsg, setErrorMsg] = useState("");
  // const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
  const lightboxRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      handleResetTimer();
    } else {
      handleClearTimer();
    }

    if (autoSend) {
      handleResend();
    }
  }, [isOpen, isResend]);

  useEffect(() => {
    if (seconds <= 0) {
      onOpenLightbox(LightBoxEnum.EmailOtpCodeExpired);
    }
  }, [seconds]);

  const handleResetTimer = () => {
    setClearTimer(false);
  };

  const handleClearTimer = () => {
    setClearTimer(true);
  };

  const handleKeyDown = (index, e) => {
    const value = e.key;
    if (value === "Backspace") {
      const newCode = [...code];
      if (!newCode[index]) {
        var goToIndex = index;

        if (newCode[index].length > 0) {
          newCode[index] = "";
        } else if (newCode[index] === "") {
          goToIndex -= 1;
          newCode[goToIndex] = "";
        }

        setCode(newCode);
        codeRefs[goToIndex].current.focus();
      }
      return;
    }
  };

  const handleChange = (index, e) => {
    let value = e.target.value;
    if (!utils.isNumber(value)) {
      return;
    }

    if (value.length > 1) {
      value = value.slice(1, 2);
    }
    const newCode = [...code];
    newCode[index] = value;

    setCode(newCode);

    if (value && index < codeRefs.length - 1) {
      codeRefs[index + 1].current.focus();
    }
  };

  const handleOnclick = (index, e) => {
    codeRefs[index].current.setSelectionRange(1, 1);
  };

  const handlePaste = (e) => {
    let pasteData = e.clipboardData.getData("text").trim().split("");
    if (pasteData.length === 6) {
      setCode(pasteData);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_URL}/${locale}/api/account/valid-email?veridationCode=${code.join("")}`;
    try {
      const res = await utils.fetchApi(url);
      if (res.success) {
        onOpenLightbox(LightBoxEnum.EmailVerified);
        window.location.href = res.redirectUrl;
      } else {
        setCode(Array(6).fill(""));
        setErrorMsg(res.errorMsg);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (e) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_URL}/${locale}/api/account/resend`;
    try {
      const data = { email };
      const res = await utils.fetchApi(url, "POST", data);
      if (res.success) {
        setClearTimer("resend");
        onOpenLightbox(LightBoxEnum.EmailResend);
      } else {
        setErrorMsg(res.errorMsg);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useFocusTrap(lightboxRef);
  useFocusManager(onClose,triggerButtonRef);

  useEffect(() => {
    if (isOpen && codeRefs[0].current) {
      codeRefs[0].current.focus();
      // setIsFocused(true);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Fragment>
      <div className={`agw-lightbox ${isOpen ? "agw-d-block" : ""}`}
        role="dialog"
        ref={lightboxRef}
        aria-labelledby="verifyEmail"
        aria-describedby="verifyEmailDescription"
      >
        <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-6">
          <div className="agw-bg-white agw-w-100">
            <div className="agw-container agw-py-6">
              <div className="agw-row agw-gy-3">
                <div className="agw-col-12">
                  <h5
                    className="agw-text-center agw-fs-title-l agw-mb-0"
                    id="verifyEmail"
                    dangerouslySetInnerHTML={{
                      __html: isResend
                        ? translations.VerificationCodeWasResent
                        : translations.verifyEmail,
                    }}
                  ></h5>
                </div>
                <div className="agw-col-12" id="verifyEmailDescription">
                  <p className="agw-text-center agw-fs-body-l agw-mb-0">
                    {translations.verificationSent} &nbsp;
                    {email}
                  </p>
                  <p className="agw-text-center agw-fs-body-l agw-mb-0">
                    {translations.enterCode}
                  </p>
                </div>
                <div className="validemail-lb">
                  <div className="lb-verification-area">
                    {codeRefs.map((ref, index) => (
                      <div className="verification-code" key={index}>
                        <input
                          type="text"
                          ref={ref}
                          value={code[index]}
                          className={`verification-input`}
                          onPaste={handlePaste}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onClick={(e) => handleOnclick(index, e)}
                        />
                      </div>
                    ))}
                  </div>
                  <span
                    className="agw-d-block agw-mt-1 agw-text-negative agw-text-center"
                    dangerouslySetInnerHTML={{
                      __html: errorMsg,
                    }}
                  ></span>
                </div>
                <div className="agw-col-12">
                  <p className="agw-text-center agw-fs-body-l agw-mb-0">
                    {translations.codeExpires} &nbsp;
                    {countdown}
                  </p>
                </div>
                <div className="agw-col-12 agw-text-center">
                  <button
                    type="button"
                    className="agw-btn agw-btn-tertiary-black"
                    onClick={onClose}
                  >
                    {translations.Cancel}
                  </button>
                  <button
                    type="submit"
                    className="agw-btn agw-btn-primary"
                    onClick={handleSubmit}
                  >
                    {translations.Submit}
                  </button>
                </div>
                <div className="agw-col-12">
                  <div className="agw-d-flex agw-flex-wrap agw-align-items-center agw-justify-content-center agw-fs-body-l agw-mb-0">
                    <span className="agw-col">{translations.didntGetCode}</span>
                    {resendSeconds > 0 ? (
                      <button
                        type="button"
                        className="agw-btn agw-btn-tertiary-black"
                        onClick={handleResend}
                        disabled
                      >
                        {translations.resendCode}&nbsp;({resendSeconds}s)
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="agw-btn agw-btn-tertiary-black"
                        onClick={handleResend}
                      >
                        {translations.resendCode}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
