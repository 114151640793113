import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Recaptcha({ recaptchaRef, formik }) {
  const handleCaptchaChange = (value) => {
      formik.setFieldValue("Recaptcha", value);
      formik.values.Recaptcha = value;  
  };

  return (
    <div className="agw-d-flex agw-justify-content-center">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
        onChange={handleCaptchaChange}
      />
    </div>
  );
}
