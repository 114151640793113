
import React, { useContext } from 'react';
import { TranslationContext } from "contexts/TranslationProvider";

const PasswordHint = ({ error, value }) => {
  const translations = useContext(TranslationContext);
  let errorComponent;
  const Negative = () => {
    return (
      <div className="agw-col-4">
        <div className="agw-mt-1 agw-pb-1 agw-bg-negative"></div>
      </div>
    );
  };

  const Warning = () => {
    return (
      <>
        <div className="agw-col-4">
          <div className="agw-mt-1 agw-pb-1 agw-bg-warning"></div>
        </div>
        <div className="agw-col-4">
          <div className="agw-mt-1 agw-pb-1 agw-bg-warning"></div>
        </div>
      </>
    );
  };

  const Positive = () => {
    return (
      <>
        <div className="agw-col-4">
          <div className="agw-mt-1 agw-pb-1 agw-bg-positive"></div>
        </div>
        <div className="agw-col-4">
          <div className="agw-mt-1 agw-pb-1 agw-bg-positive"></div>
        </div>
        <div className="agw-col-4">
          <div className="agw-mt-1 agw-pb-1 agw-bg-positive"></div>
        </div>
      </>
    );
  };

  if (error == translations.LengthError) {
    errorComponent = <Negative />;
  } else if (!value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@@#$%^&*()_+]{9,}$/) && value.length > 7) {
    errorComponent = <Warning />;
  } else if (!error && value) {
    errorComponent = <Positive />;
  }

  return (
    <div className="agw-row agw-gx-1 agw-col-8">
      {errorComponent}
    </div>
  );
}

export default PasswordHint;