import { useState } from 'react';

const useLightbox = () => {
  const [activeLightbox, setActiveLightbox] = useState(null);

  const openLightbox = (lightboxId) => {
    setActiveLightbox(lightboxId);
  };

  const closeAllLightboxes = () => {
    setActiveLightbox(null);
  };

  return [activeLightbox, openLightbox, closeAllLightboxes];
};

export default useLightbox;
