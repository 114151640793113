import React, { useContext } from "react";
import { TranslationContext } from "../../contexts/TranslationProvider";

// Otp驗證成功後顯示
export const EmailVerified = ({ isOpen, onClose }) => {
  const translations = useContext(TranslationContext);

  return (
    <div className={`agw-lightbox ${isOpen ? "agw-d-block" : ""}`} role="dialog" aria-labelledby="emailVerified">
      <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-6">
        <div className="agw-bg-white agw-w-100">
          <div className="agw-container agw-py-6">
            <div className="agw-row agw-gy-3">
              <div className="agw-col-12 agw-text-center">
                <div className="loading">
                  <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="agw-col-12">
                <h5 className="agw-text-center agw-fs-title-l" id="emailVerified" tabIndex="-1">
                  {translations.EmailVerified}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
