import React from "react";
import { useParams } from "react-router-dom";
import { TranslationProvider } from "./contexts/TranslationProvider";
import { LoadingProvider } from "./contexts/LoadingProvider";
import { PrevLinkProvider } from "./contexts/PrevLinkProvider";
import Loading from "./components/Loading";
import Header from "components/Header";
import Footer from "components/Footer";

function Layout({ children }) {
  const { locale } = useParams();

  return (
    <LoadingProvider>
      <PrevLinkProvider>
        <TranslationProvider locale={locale}>
          <Header />
          <div className="agw-container" style={{ minHeight: "60vh" }}>
            <main role="main" className="agw-pb-6 agw-row agw-flex-column">
              {children}
            </main>
          </div>
          <Footer />
          <Loading />
        </TranslationProvider>
      </PrevLinkProvider>
    </LoadingProvider>
  );
}

export default Layout;
