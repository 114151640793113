import { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLoading } from './LoadingProvider';

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const locale = useParams().locale;
  const [translations, setTranslations] = useState({});
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    setIsLoading(true);
    const fetchTranslations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/${locale}/api/account/getResource`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.ok) {
          const translationsData = await response.json();
          setTranslations(translationsData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        console.log(`${process.env.REACT_APP_URL}/${locale}/api/account/getResource`);
      }
    };

    fetchTranslations();
  }, [locale]);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
}
