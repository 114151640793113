import React, { useState, useContext, Fragment, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";
import * as validationSchemas from "../validation/validationSchemas";
import { usePWshow } from "hooks/useCustom";
import { useFormik } from "formik";

function UpdatePassword() {
  const locale = useParams().locale;
  const navigate = useNavigate();
  const location = useLocation();
  const translations = useContext(TranslationContext);
  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);
  const { isLoading, setIsLoading } = useLoading();
  const [resetErrorMsg, setResetErrorMsg] = useState("");
  const token = window.location.href.split("=")[1];

  useEffect(() => {
    const fetchSelectItems = async () => {
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams(location.search.toLowerCase());
        const token = queryParams.get("password_token") ?? "";
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/update-password?token=${token}`;
        const res = await utils.fetchApi(url);

        if (res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }
        else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSelectItems();
  }, []);



  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema:
      validationSchemas.updatePasswordValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      const postData = { ...values, Code: token };

      try {
        const postUrl = `${process.env.REACT_APP_URL}/${locale}/api/account/update-password`;
        const res = await utils.fetchApi(postUrl, "POST", postData);
        if (res.success) {
          navigate(`/${locale}/account/update-password-complete`);
        } else {
          setResetErrorMsg(res.errorMsg);
          res.errors.forEach(async (error) => {
            await formik.setFieldError(`${error.id}`, error.errorMsg);
            const errorInput = document.getElementsByName(`${error.id}`)[0];
            if (errorInput) {
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <Fragment>
      <div className="agw-col-md-5 agw-mx-auto agw-mb-5">
        <h1 className="agw-fw-semibold agw-fs-headline-l agw-text-center agw-mb-3">
          {translations.UpdatePassword}
        </h1>
      </div>

      <form className="agw-col-md-5 agw-mx-auto" onSubmit={formik.handleSubmit}>
        <div className="agw-row agw-gy-3">
          <div className="agw-col-12">
            <label
              htmlFor="NewPassword"
              className="agw-form-label agw-fw-semibold"
            >
              {translations["New Password"]}{" "}
              <span className="agw-text-negative">*</span>
            </label>
            <div className="agw-po-relative">
              <input
                type={isPwShow ? "text" : "password"}
                className={`agw-form-control ${formik.touched.NewPassword && formik.errors.NewPassword
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="NewPassword"
                name="NewPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.NewPassword}
                aria-invalid="true"
                aria-required="true"
              />
              <button
                className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                style={{ transform: "translate(-100%,-50%)" }}
                type="button"
                onClick={(e) => handlePwShow(e)}
              >
                <svg
                  id="icon-visibility-off"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}
                  role="img"
                >
                  <title>{translations.hidePassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-off"
                    d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                  />
                </svg>
                <svg
                  id="icon-visibility-on"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}
                  role="img"
                >
                  <title>{translations.showPassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-on"
                    d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                  />
                </svg>
              </button>
            </div>
            {formik.touched.NewPassword && formik.errors.NewPassword ? (
              <span
                dangerouslySetInnerHTML={{ __html: formik.errors.NewPassword }}
                className="agw-d-block agw-mt-1 agw-text-negative"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label
              htmlFor="ConfirmNewPassword"
              className="agw-form-label agw-fw-semibold"
            >
              {translations.ConfirmPassword}{" "}
              <span className="agw-text-negative">*</span>
            </label>
            <div className="agw-po-relative">
              <input
                type={isConfirmPwShow ? "text" : "password"}
                className={`agw-form-control ${formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="ConfirmNewPassword"
                name="ConfirmPassword"
                value={formik.values.ConfirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-invalid="true"
                aria-required="true"
              />
              <button
                className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                style={{ transform: "translate(-100%,-50%)" }}
                type="button"
                onClick={(e) => handleConfirmPwShow(e)}
              >
                <svg
                  id="icon-visibility-off"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isConfirmPwShow ? "agw-d-none" : ""}`}
                  role="img"
                >
                  <title>{translations.hidePassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-off"
                    d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                  />
                </svg>
                <svg
                  id="icon-visibility-on"
                  viewBox="0 0 24 24"
                  className={`icons_24 agw-pe-none ${isConfirmPwShow ? "" : "agw-d-none"}`}
                  role="img"
                >
                  <title>{translations.showPassword}</title>
                  <rect id="mask" width="24" height="24" fill="none" />
                  <path
                    id="visibility-on"
                    d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                  />
                </svg>
              </button>
            </div>
            {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: formik.errors.ConfirmPassword,
                }}
                className="agw-d-block agw-mt-1 agw-text-negative"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-gy-5">
            <p className="agw-text-negative agw-text-center">{resetErrorMsg}</p>
            <button
              type="submit"
              className="agw-btn agw-btn-block agw-btn-primary"
              disabled={formik.isSubmitting}
            >
              {translations["Change Password"]}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default UpdatePassword;
