import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import { usePrevLink } from "../contexts/PrevLinkProvider";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";

function Footer() {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  const location = useLocation();
  const currentUrl = window.location.origin + location.pathname;
  const currentYear = new Date().getFullYear();
  const { isLoading, setIsLoading } = useLoading();
  const { prevLink, setPrevLink } = usePrevLink();
  const [languageSelectorData, setLanguageSelectorData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchLanguageSelector = async () => {
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/getwebsite`;
        const response = await utils.fetchApi(url);

        setLanguageSelectorData(response);
      } catch (error) {
        console.error(error);
        console.log(
          `${process.env.REACT_APP_URL}/${locale}/api/account/getwebsite`
        );
      }
    };

    fetchLanguageSelector();
    setIsLoading(false);
  }, [locale]);

  const handleButtonClick = () => {
    const langLocale = window.location.pathname.split("account/")[1];
    console.log("Previous Page Path:", langLocale);

    if (langLocale !== "languages") {
      setPrevLink(langLocale);
    }
  };

  return (
    <footer className="agw-bg-200 agw-pt-3 agw-pb-5">
      <div className="agw-d-flex agw-flex-column agw-align-items-center">
        <div className="footer-links agw-mb-3">
          <div className="footer-links_item">
            <Link
              className="agw-fs-body-s animation_hover"
              to={`/${locale}/privacy`}
              target="_blank"
            >
              {translations.Privacy}
            </Link>
          </div>
          <div className="footer-links_item">
            <Link
              className="agw-fs-body-s animation_hover"
              to={`/${locale}/legal`}
              target="_blank"
            >
              {translations.Legal}
            </Link>
          </div>
        </div>
        {!currentUrl.includes("business-sign-up") ? (
          <Link
            className="agw-d-inline-flex agw-align-items-center agw-justify-content-center agw-mb-3 agw-fs-body-s agw-fw-semibold agw-text-decoration-none"
            to={`/${locale}/account/languages`}
            onClick={handleButtonClick}
          >
            <svg className="icons_20 agw-mr-1" viewBox="0 0 24 24">
              <g id="uuid-ae90f881-9b38-4800-9a74-3a4027ef63d7">
                <path d="m24,12C24,5.78,19.26.66,13.19.06h0c-.39-.04-.79-.06-1.19-.06s-.8.02-1.19.06h0C4.74.66,0,5.78,0,12s4.72,11.32,10.77,11.94h0c.4.04.81.06,1.23.06s.8-.02,1.19-.06h0c6.07-.6,10.81-5.71,10.81-11.94Zm-2.33,2.5h-4.44c.31-1.69.31-3.31,0-5h4.44c.21.8.33,1.63.33,2.5s-.12,1.7-.33,2.5Zm-9.67,7.5s-.03,0-.04,0c-1.17-2.06-2.04-3.85-2.61-5.5h5.3c-.58,1.65-1.45,3.44-2.63,5.5,0,0,0,0,0,0Zm-3.21-7.5c-.35-1.7-.35-3.3,0-5h6.41c.35,1.69.35,3.3,0,5h-6.41Zm3.21-12.5s0,0,.01,0c1.18,2.07,2.06,3.85,2.64,5.5h-5.29c.58-1.65,1.45-3.44,2.63-5.5,0,0,0,0,.01,0Zm8.92,5.5h-4.19c-.5-1.6-1.26-3.3-2.27-5.19,2.83.72,5.17,2.64,6.46,5.19ZM9.54,2.32c-1.01,1.89-1.77,3.59-2.27,5.19H3.08c1.29-2.55,3.64-4.47,6.46-5.19Zm-7.21,7.19h4.44c-.31,1.68-.31,3.31,0,5H2.33c-.21-.8-.33-1.64-.33-2.5s.12-1.7.33-2.5Zm.75,7h4.19c.49,1.59,1.24,3.29,2.24,5.17-2.81-.72-5.14-2.64-6.43-5.17Zm11.38,5.18c1.01-1.89,1.77-3.58,2.27-5.18h4.19c-1.29,2.55-3.63,4.46-6.46,5.18Z" />
              </g>
            </svg>
            {languageSelectorData
              .filter(
                (values) =>
                  values.countryCode.includes(locale.split("-")[0]) &&
                  values.languageCode.includes(locale.split("-")[1])
              )
              .map((item) => (
                <span key={item.countryCode}>
                  {item.countryName} - {item.languageName}
                </span>
              ))}
          </Link>
        ) : null}
        <div>
          <span className="agw-fs-body-s">&copy; {currentYear} Acer Inc.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
