import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";

function UpdatePasswordComplete() {
  const translations = useContext(TranslationContext);
  const locale = useParams().locale;

  return (
    <div className="agw-pt-6" aria-live="assertive" aria-atomic="true">
      <div
        className="agw-col-md-6 agw-mx-auto agw-fs-body-l agw-text-center"
        dangerouslySetInnerHTML={{
          __html: utils.replaceStrToNum(translations.ResetPwdComplete, locale),
        }}
      ></div>
    </div>
  );
}

export default UpdatePasswordComplete;
