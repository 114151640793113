import {React, useState, useEffect} from "react";
import { Link, useParams, useLocation } from "react-router-dom";

function Header() {
  const locale = useParams().locale;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search.toLowerCase());
  const isLGMask = queryParams.get("lgmask") === "1"  ? false: true;

  return (
    <header className="agw-container">
      <div className="agw-py-5">
      {isLGMask && 
         <Link className="agw-btn agw-m-auto agw-d-block" onClick={(e) => { window.location.href = `/${locale}` }}>
          <img className="header-logo agw-mx-auto agw-d-block" src="https://images.acer.com/is/content/acer/acer-4" alt="acer logo" />
        </Link>
      }
      </div>
    </header>
  );
}

export default Header;