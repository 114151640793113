import * as Yup from "yup";
import * as utils from "../helpers/utilityHelpers";

export const signInValidationSchema = (translations) => {
  return Yup.object().shape({
    Account: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg),
    Password: Yup.string().required(translations.EmptyErrorMsg),
  });
};

export const enterNewPwdValidationSchema = (translations) => {
  return Yup.object().shape({
    NewPassword: Yup.string().min(8, translations.LengthError)
    .matches(/^\S*$/, translations.FormatError)
    .required(translations.EmptyErrorMsg),
    ConfirmNewPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword"), null], translations.InconsistentPasswords)
    .required(translations.EmptyErrorMsg),
  });
};

export const resetPasswordValidationSchema = (translations) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};

export const updatePasswordValidationSchema = (translations) => {
  return Yup.object().shape({
    NewPassword: Yup.string()
      .min(8, translations.LengthError)
      .matches(/^\S*$/, translations.FormatError)
      .required(translations.EmptyErrorMsg),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("NewPassword"), null], translations.InconsistentPasswords)
      .required(translations.EmptyErrorMsg),
  });
};

export const signUpValidationSchema = (translations) => {
  return Yup.object().shape({
    Email: Yup.string()
      .required(translations.EmptyErrorMsg)
      .email(translations.EmailError),
    password: Yup.string()
      .matches(/^\S*$/, translations.FormatError)
      .required(translations.EmptyErrorMsg)
      .min(8, translations.LengthError),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translations.InconsistentPasswords)
      .required(translations.EmptyErrorMsg),
    firstName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50)),
    lastName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50)),
    CountryCode: Yup.string().required(translations.EmptyErrorMsg),
    LanguageCode: Yup.string().required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};

export const businessSignUpValidationSchema = (translations) => {
  return Yup.object().shape({
    ...signUpValidationSchema(translations).fields,
    BusinessId: Yup.string()
      .max(20, utils.replaceStrToNum(translations.TheFieldIsLimited, 20))
      .required(translations.EmptyErrorMsg),
    businessName: Yup.string()
      .max(60, utils.replaceStrToNum(translations.TheFieldIsLimited, 60))
      .required(translations.EmptyErrorMsg),
    Address: Yup.string()
      .min(2, utils.replaceStrToNum(translations.MoreThanErrorMsg, 2))
      .max(80, utils.replaceStrToNum(translations.LessThenErrorMsg, 80))
      .required(translations.EmptyErrorMsg),
    City: Yup.string()
      .min(2, utils.replaceStrToNum(translations.MoreThanErrorMsg, 2))
      .max(40, utils.replaceStrToNum(translations.LessThenErrorMsg, 40))
      .required(translations.EmptyErrorMsg),
    ZipCode: Yup.string().required(translations.EmptyErrorMsg),
  });
};

export const federatedSignUpValidationSchema = (translations) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg),
    firstName: Yup.string().required(translations.EmptyErrorMsg),
    lastName: Yup.string().required(translations.EmptyErrorMsg),
    CountryCode: Yup.string().required(translations.EmptyErrorMsg),
    LanguageCode: Yup.string().required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};
