import React, { Fragment, useState, useContext } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import * as utils from "../helpers/utilityHelpers";
import { useLoading } from "../contexts/LoadingProvider";
import { TranslationContext } from "../contexts/TranslationProvider";
import * as validationSchemas from "../validation/validationSchemas";
import { useFormik } from "formik";
import Recaptcha from "components/Recaptcha";
import { useRecaptcha } from "hooks/useRecaptcha";

function ResetPassword() {
  const locale = useParams().locale;
  const location = useLocation();
  const navigate = useNavigate();
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();
  const [resetErrorMsg, setResetErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const recaptchaRef = React.useRef();
  const {resetRecaptcha} = useRecaptcha(recaptchaRef);

  const formik = useFormik({
    initialValues: {
      email: "",
      Recaptcha: "",
    },
    validationSchema:
      validationSchemas.resetPasswordValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setResetErrorMsg("");
      setIsLoading(true);

      try {
        const queryParams = new URLSearchParams(location.search.toLowerCase());
        const clientId = queryParams.get("clientid") ?? "";
        const redirectUrl = queryParams.get("redirecturl") ?? "";
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/reset-password?clientId=${clientId}&RedirectUrl=${redirectUrl}`;
        const res = await utils.fetchApi(url, "POST", values);

        if (res.success) {
          navigate(`/${locale}/account/reset-password-verify`);
        } else {
          // setResetErrorMsg(res.errorMsg);
          setEmailErrorMsg(res.errorMsg);
          resetRecaptcha();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  return (
    <Fragment>
      <div className="agw-col-md-5 agw-mx-auto agw-mb-5">
        <h1 className="agw-fw-semibold agw-fs-headline-l agw-text-center agw-mb-3">
          {translations["Reset Password"]}
        </h1>
        <p className="agw-fs-title-s agw-mb-0">
          {translations.ResetPasswordInfo}
        </p>
      </div>

      <form className="agw-col-md-5 agw-mx-auto" onSubmit={formik.handleSubmit}>
        <div className="agw-row agw-gy-3">
          <div className="agw-col-12">
            <label
              htmlFor="EmailAddress"
              className="agw-form-label agw-fw-semibold"
            >
              {translations.emailAddressRegister}{" "}
              <span className="agw-text-negative">*</span>
            </label>
            <input
              type="email"
              className={`agw-form-control ${
                formik.touched.email && formik.errors.email
                  ? "agw-border-negative"
                  : ""
              }`}
              id="EmailAddress"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              aria-describedby="EmailAddress"
              aria-required="true"
              placeholder={translations.PleaseEnterEmail}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: emailErrorMsg,
              }}
              className="agw-d-block agw-mt-1 agw-text-negative"
            />
          </div>
          <div className="agw-col-12 agw-my-5">
            <Recaptcha
              recaptchaRef={recaptchaRef}
              formik={formik}
            />
            {formik.touched.Recaptcha && formik.errors.Recaptcha ? (
              <span className="agw-d-block agw-mt-1 agw-text-center agw-text-negative">
                {formik.errors.Recaptcha}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-gy-2">
            {resetErrorMsg ? (
              <p
                className="agw-text-negative agw-text-center"
                dangerouslySetInnerHTML={resetErrorMsg}
              ></p>
            ) : null}
            <button
              type="submit"
              role="link"
              className="agw-btn agw-btn-block agw-btn-primary"
              disabled={!formik.isSubmitting && !formik.values.email}
            >
              {translations["Reset Password"]}
            </button>
          </div>
          <div className="agw-col-12 agw-text-center">
            <Link
              className="agw-btn agw-btn-block agw-text-decoration-none agw-fs-body-l"
              to={`/${locale}/account/sign-in`}
            >
              <span className="animation_hover">{translations.SignIn}</span>
            </Link>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default ResetPassword;
