import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import { TranslationContext } from 'contexts/TranslationProvider';

function UpdatePasswordComplete() {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);


  return (
    <div className="agw-pt-6">
      <div className="agw-col-md-6 agw-mx-auto agw-text-center" dangerouslySetInnerHTML={{ __html: translations.PropsectWelcomePage }}></div>
    </div>
  )
}

export default UpdatePasswordComplete;