import React, { useContext, useRef } from "react";
import { TranslationContext } from "../../contexts/TranslationProvider";
import useFocusTrap from '../../hooks/useFocusTrap';
import useFocusManager from '../../hooks/useFocusManager';

// 密碼過期更新密碼的畫面
export const PasswordNotChanged = ({ isOpen, onClose, onChangePasswordClick, triggerButtonRef }) => {
  const translations = useContext(TranslationContext);
  const lightboxRef = useRef(null);

  useFocusTrap(lightboxRef);
  useFocusManager(onClose, triggerButtonRef);
  if (!isOpen) return null;

  return (
    <div className="agw-lightbox agw-d-block show" role="dialog" ref={lightboxRef} aria-labelledby="passwordNotChanged">
      <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-7">
        <div className="agw-bg-white agw-w-100">
          <div className="agw-container agw-py-4">
            <div className="agw-row agw-gy-3">
              <div className="agw-col-12">
                <h5 className="agw-text-center agw-fs-title-l" id="passwordNotChanged" tabIndex="-1">
                  {translations.PasswordNotChanged}
                </h5>
              </div>
              <div className="agw-col-12 agw-text-center">
                <button 
                  type="button" 
                  className="agw-btn agw-btn-primary"
                  onClick={onChangePasswordClick}
                >
                  {translations["Change Password"]}
                </button>
                <button
                  type="button"
                  className="agw-btn agw-btn-tertiary-black agw-mx-3"
                  onClick={onClose}
                >
                  {translations.Cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
