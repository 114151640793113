import React, { Fragment, useContext, useRef } from "react";
import { TranslationContext } from "../../contexts/TranslationProvider";
import { LightBoxEnum } from "../Enum";
import useFocusTrap from '../../hooks/useFocusTrap';
import useFocusManager from '../../hooks/useFocusManager';

// 驗證碼過期
export const VerificationCodeHasExpired = ({
  isOpen,
  onClose,
  onOpenLightbox,
  triggerButtonRef
}) => {
  const translations = useContext(TranslationContext);
  const lightboxRef = useRef(null);

  useFocusTrap(lightboxRef);
  useFocusManager(onClose, triggerButtonRef);
  if (!isOpen) return null;

  return (
    <Fragment>
      <div className={`agw-lightbox ${isOpen ? "agw-d-block" : ""}`}
        role="dialog"
        ref={lightboxRef}
        aria-labelledby="codeExpired"
      >
        <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-6">
          <div className="agw-bg-white agw-w-100">
            <div className="agw-container agw-py-6">
              <div className="agw-row agw-gy-3">
                <div className="agw-col-12"
                  id="codeExpired"
                  aria-live="assertive"
                  aria-atomic="true">
                  <h5 className="agw-text-center agw-fs-title-l" tabIndex="-1">
                    {translations.codeExpired}
                  </h5>
                  <h5 className="agw-text-center agw-fs-title-l" tabIndex="-1">
                    {translations.PleaseClickResendCode}
                  </h5>
                </div>
                <div className="agw-col-12 agw-text-center">
                  <button
                    type="button"
                    className="agw-btn agw-btn-tertiary-black"
                    onClick={onClose}
                  >
                    {translations.Cancel}
                  </button>
                  <button
                    type="button"
                    className="agw-btn agw-btn-primary"
                    onClick={() => {
                      onOpenLightbox(LightBoxEnum.EmailResendfromExpired);
                    }}
                  >
                    {translations.resendCode}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
