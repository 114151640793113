import { useState, useEffect } from "react";

const CountdownTimer = ({ initialSeconds, clearTimer }) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    setSeconds(initialSeconds);
    if (clearTimer) {
      //clear timer
      setIsActive(false);
    } else {
      //reset timer
      setIsActive(true);
    }
  }, [clearTimer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const countdown = formatTime(seconds);

  return [seconds, countdown, isActive, setIsActive];
};

export default CountdownTimer;
