import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { usePWshow } from "hooks/useCustom";
import useLightbox from "../hooks/useLightbox";
import { LightBoxEnum } from "components/Enum";
import { useLoading } from "../contexts/LoadingProvider";
import { TranslationContext } from "contexts/TranslationProvider";
import HeadlineContext from "contexts/HeadlineProvider";
import Recaptcha from "components/Recaptcha";
import { useRecaptcha } from "hooks/useRecaptcha";
import * as utils from "../helpers/utilityHelpers";
import * as validateHelper from "../helpers/validationHelpers";
import * as validationSchemas from "../validation/validationSchemas";
import { useFormik } from "formik";
import ActiveLightbox from "../components/ActiveLightbox";
import PasswordHint from "../components/PasswordHint";
import "styles/negative-link.css";

const Business = () => {
  const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
  const locale = useParams().locale;
  const location = useLocation();
  const currentSearch = location.search;
  const { setHeadLine, setB2BsignUpTabVisible } = useContext(HeadlineContext);

  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [emailBackendErrorMsg, setEmailBackendErrorMsg] = useState("");
  const [zipCodeBackendErrorMsg, setZipCodeBackendErrorMsg] = useState("");
  const [phoneBackendErrorMsg, setPhoneBackendErrorMsg] = useState("");
  const [businessIdBackendErrorMsg, setBusinessIdBackendErrorMsg] =
    useState("");
  const [agreeToCreateAccount, setAgreeToCreateAccount] = useState("");
  const [countrySelectItems, setCountrySelectItems] = useState([]);
  const [languageSelectItems, setLanguageSelectItems] = useState([]);
  const [citySelectItems, setCitySelectItems] = useState(false);
  const [stateVisible, setStateVisible] = useState([]);
  const recaptchaRef = React.useRef();
  const triggerButtonRef = useRef(null);
  const {resetRecaptcha} = useRecaptcha(recaptchaRef);

  useEffect(() => {
    setIsLoading(true);
    const fetchSelectItems = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const state = utils.getQueryParamIgnoreCase(queryParams, "state") ?? "";
        const clientId = utils.getQueryParamIgnoreCase(queryParams,"clientid") ?? "";
        const redirectUrl = utils.getQueryParamIgnoreCase(queryParams,"redirecturl") ? 
                          utils.getQueryParamIgnoreCase(queryParams,"redirecturl") : (utils.getQueryParamIgnoreCase(queryParams,"redirect_uri") ?? "");
        const redirectlocale = window.location.href.split(/\%2F/)[3];
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/business-sign-up?clientId=${clientId}&RedirectUrl=${redirectUrl}&state=${state}`;
        const res = await utils.fetchApi(url);

        if (res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }

        if (redirectlocale === "en-ie") {
          formik.values.CountryCode = "IE";
        } else {
          formik.values.CountryCode = res.countryCode;
        }

        setCountrySelectItems(res.countrySelectItems);
        setLanguageSelectItems(res.languageSelectItems);
        setCitySelectItems(res.citySelectItems);
        setStateVisible(res.stateVisible);
        setHeadLine(res.headline);
        setB2BsignUpTabVisible(res.b2BsignUpTabVisible);
        setAgreeToCreateAccount(res.agreeToCreateAccount);
        formik.values.ContactAgreement = res.signupOpinPretick;
        formik.values.MobileColValidation = res.mobileColValidation;
        formik.values.LanguageCode = res.languageCode;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSelectItems();
  }, []);

  const formik = useFormik({
    initialValues: {
      Email: "",
      password: "",
      Province: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      CountryCode: "",
      LanguageCode: "",
      BusinessId: "",
      businessName: "",
      Phone: "",
      Address: "",
      City: "",
      ZipCode: "",
      ContactAgreement: false,
      Recaptcha: "",
      MobileColValidation: ""
    },
    validationSchema:
      validationSchemas.businessSignUpValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      var messagesCheck = [phoneBackendErrorMsg, businessIdBackendErrorMsg];
      if (!messagesCheck.every(item => item === '')) {
        return;
      }
      setErrorMsg("");
      setEmailBackendErrorMsg("");
      setBusinessIdBackendErrorMsg("");
      setZipCodeBackendErrorMsg("");
      setIsLoading(true);

      try {
        const url = `${process.env.REACT_APP_URL}/${formik.values.CountryCode === "IE" ? "ie-en" : locale
          }/api/account/business-sign-up`;
        const res = await utils.fetchApi(url, "POST", values);
        if (res.success) {
          openLightbox(LightBoxEnum.EmailOtpCode);
        } else {
          setErrorMsg(res.errorMsg);
          res.errors.forEach(async (error) => {
            await formik.setFieldError(`${error.id}`, error.errorMsg);
            const errorInput = document.getElementsByName(`${error.id}`)[0];
            if (errorInput) {
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
        resetRecaptcha();
      }
    },
  });

  const handleEmailBlur = async (e) => {
    setEmailBackendErrorMsg("");

    const res = await validateHelper.checkEmailExists(
      e.target.value,
      locale,
      translations
    );
    if (!res.success) {
      await formik.setTouched({ ...formik.touched, Email: true });

      if (formik.values.Email.trim() === "") {
        setEmailBackendErrorMsg(translations.EmptyErrorMsg);
      } else {
        setEmailBackendErrorMsg(res.error);
      }
    }
  };

  const handleVatBlur = async (e) => {
    if (formik.values.BusinessId.trim() === "") {
      setBusinessIdBackendErrorMsg(translations.EmptyErrorMsg);
      return;
    }

    const postData = formik.values.BusinessId;
    const selectedCountry = formik.values.CountryCode;
    const postUrl = `${process.env.REACT_APP_URL}/${locale}/api/account/check-vat?vat=${postData}&&selectedCountry=${selectedCountry}`;

    setIsLoading(true);
    try {
      const response = await utils.fetchApi(postUrl);
      await formik.setTouched({ ...formik.touched, BusinessId: true });
      setBusinessIdBackendErrorMsg("");

      if (!response.success) {
        setBusinessIdBackendErrorMsg(response.errorMsg);
      } else if (response.companyName) {
        await formik.setFieldValue("businessName", response.companyName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryCodeChange = handleVatBlur;

  useEffect(() => {
    setZipCodeBackendErrorMsg("");
    if (formik.values.ZipCode !== "") {
      handleZipCodeBlur();
    }
    handleCountryCodeChange();
  }, [formik.values.CountryCode]);

  const handleZipCodeBlur = async (e) => {
    const postData = formik.values.ZipCode;
    let postLocale;

    if (formik.values.CountryCode === "AT") {
      postLocale = "at-de";
    } else if (formik.values.CountryCode === "IE") {
      postLocale = "ie-en";
    } else {
      postLocale = locale;
    }

    const postUrl = `${process.env.REACT_APP_URL}/${postLocale}/api/account/check-zipcode?zipCode=${postData}`;

    setIsLoading(true);
    try {
      const response = await utils.fetchApi(postUrl);
      await formik.setTouched({ ...formik.touched, ZipCode: true });
      setZipCodeBackendErrorMsg(response.errorMsg);

      if (!response.success) {
        setZipCodeBackendErrorMsg(response.errorMsg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordBlur = async (e) => {
    const passwordValue = e.target.value;
    if (passwordValue.length > 7) {
      await formik.setTouched({ ...formik.touched, password: true });
      if (!passwordValue.match(/^\S*$/)) {
        await formik.setFieldError("password", translations.FormatError);
      }
      else if (!passwordValue.match(
        /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@@#$%^&*()_+]{9,}$/
      )) {
        await formik.setFieldError("password", translations.PasswordHint);
      }
    }
  };


  const handlePhoneBlur = async (e) => {
    const phoneVal = e.target.value;
    setPhoneBackendErrorMsg("");
    await formik.setTouched({ ...formik.touched, Phone: true });
    if (phoneVal.length === 0) {
      console.log(translations.EmptyErrorMsg);
      setPhoneBackendErrorMsg(translations.EmptyErrorMsg);
    }
    else if (formik.values.MobileColValidation !== "") {
      switch (locale) {
        case "gb-en": case "fr-fr": case "it-it": case "de-de": case "pl-pl": case "nl-nl":
        case "es-es": case "ie-en": case "ch-fr": case "ch-de": case "dk-da": case "fi-fi":
        case "no-no": case "se-sv": case "be-nl": case "ee-en": case "sr-rs": case "be-fr":
        case "hu-hu": case "cz-cs": case "de-at": case "tr-tr": case "gr-el": case "pt-pt":
        case "bg-bg": case "hr-hr": case "ro-ro": case "ru-ru": case "ua-uk": case "si-en":
          if (!phoneVal.match(formik.values.MobileColValidation)) {
            setPhoneBackendErrorMsg(translations.FormatError);
          }
          break;
      }
    }
  };


  const handlePolicyChange = async (e) => {
    setIsChecked(!isChecked);
  };

  return (
    <Fragment>
      <div className="agw-tab-pane">
        <div className="agw-mb-5 agw-col-12 agw-col-md-5 agw-mx-auto">
          <p className="agw-fs-title-s agw-mb-3">
            {translations.BusinessSignUpDescription}
          </p>
          <p className="agw-fs-title-s agw-mb-0">
            {translations.alreadyAMember}&nbsp;
            <Link
              className="agw-fw-semibold agw-link-acer agw-text-decoration-underline agw-pe-auto"
              style={{ cursor: "pointer" }}
              to={`/${locale}/account/sign-in${currentSearch}`}
            >
              {translations.SignIn}
            </Link>
          </p>
        </div>

        <form
          className="agw-col-md-5 agw-mx-auto"
          onSubmit={formik.handleSubmit}
        >
          <div className="agw-row agw-gy-3">
            <div className="agw-col-12">
              <label htmlFor="Email" className="agw-form-label agw-fw-semibold">
                {translations.emailAddressRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="Email"
                className={`agw-form-control ${formik.touched.Email &&
                  (formik.errors.Email || emailBackendErrorMsg)
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="Email"
                name="Email"
                onChange={formik.handleChange}
                onBlur={handleEmailBlur}
                value={formik.values.Email}
                aria-invalid={formik.touched.Email && formik.errors.Email ? true : false}
                aria-required="true"
                aria-describedby="emailError"
              />
              {formik.touched.Email &&
                (formik.errors.Email || emailBackendErrorMsg) ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formik.errors.Email ?? emailBackendErrorMsg,
                  }}
                  className="agw-d-block agw-mt-1 agw-text-negative"
                  id="emailError"
                  aria-live="assertive" aria-atomic="true"
                ></span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="password"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.passwordRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <div className="agw-po-relative">
                <input
                  type={isPwShow ? "text" : "password"}
                  className={`agw-form-control ${formik.touched.password &&
                    formik.errors.password &&
                    formik.errors.password !== translations.PasswordHint
                    ? "agw-border-negative"
                    : ""
                    }`}
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, password: true });
                    formik.handleChange(e);
                    handlePasswordBlur(e);
                  }}
                  value={formik.values.password}
                  aria-invalid={formik.touched.password && formik.errors.password ? true : false}
                  aria-required="true"
                  aria-describedby="passwordError"
                />
                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handlePwShow(e); }}
                  aria-label={`${isPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <svg
                    id="icon-visibility-off"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}
                    role="img"
                  >
                    <title>{translations.hidePassword = 'undefined' ? 'Hide Password' : translations.hidePassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-off"
                      d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                    />
                  </svg>
                  <svg
                    id="icon-visibility-on"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}
                    role="img"
                  >
                    <title>{translations.showPassword = 'undefined' ? 'Show Password' : translations.showPassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-on"
                      d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                    />
                  </svg>
                </button>
              </div>
              <PasswordHint
                error={formik.errors.password}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <span
                  className={`agw-d-block agw-mt-1 ${formik.errors.password === translations.PasswordHint
                    ? "agw-text-900"
                    : "agw-text-negative"
                    }`}
                  id="passwordError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.password}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="confirmPassword"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.ConfirmPassword}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <div className="agw-po-relative">
                <input
                  type={isConfirmPwShow ? "text" : "password"}
                  className={`agw-form-control ${formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                    ? "agw-border-negative"
                    : ""
                    }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onPaste={(e) => e.preventDefault()}
                  aria-required="true"
                  aria-invalid={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                  aria-describedby="confirmPasswordError"
                />
                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handleConfirmPwShow(e); }}
                  aria-label={`${isConfirmPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <svg
                    id="icon-visibility-off"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isConfirmPwShow ? "agw-d-none" : ""}`}
                    role="img"
                  >
                    <title>{translations.hidePassword = 'undefined' ? 'Hide Password' : translations.hidePassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-off"
                      d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                    />
                  </svg>
                  <svg
                    id="icon-visibility-on"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isConfirmPwShow ? "" : "agw-d-none"}`}
                    role="img"
                  >
                    <title>{translations.showPassword = 'undefined' ? 'Show Password' : translations.showPassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-on"
                      d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                    />
                  </svg>
                </button>
              </div>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="confirmPasswordError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.confirmPassword}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="firstName"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.firstNameRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.firstName && formik.errors.firstName
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                aria-required="true"
                aria-invalid={formik.touched.firstName && formik.errors.firstName ? true : false}
                aria-describedby="firstNameError"
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="firstNameError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.firstName}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="lastName"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.lastNameRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.lastName && formik.errors.lastName
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                aria-required="true"
                aria-invalid={formik.touched.lastName && formik.errors.lastName ? true : false}
                aria-describedby="lastNameError"
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="lastNameError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.lastName}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="BusinessId"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.BusinessId}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.BusinessId &&
                  (formik.errors.BusinessId || businessIdBackendErrorMsg)
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="BusinessId"
                name="BusinessId"
                placeholder={
                  sessionStorage.locale === "IE"
                    ? "IE1234567WH"
                    : translations.BusinessIdPlaceholder
                }
                onChange={formik.handleChange}
                onBlur={handleVatBlur}
                value={formik.values.BusinessId}
                aria-required="true"
                aria-invalid={formik.touched.BusinessId && formik.errors.BusinessId ? true : false}
                aria-describedby="BusinessIdError"
              />
              {formik.touched.BusinessId &&
                (formik.errors.BusinessId || businessIdBackendErrorMsg) ? (
                <span
                  className="agw-d-block agw-mt-1 agw-text-negative"
                  dangerouslySetInnerHTML={{
                    __html:
                      formik.errors.BusinessId ?? businessIdBackendErrorMsg,
                  }}
                  id="BusinessIdError"
                  aria-live="assertive" aria-atomic="true"
                ></span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="businessName"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.BusinessName}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.businessName && formik.errors.businessName
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="businessName"
                name="businessName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.businessName}
                aria-required="true"
                aria-invalid={formik.touched.businessName && formik.errors.businessName ? true : false}
                aria-describedby="businessNameError"
              />
              {formik.touched.businessName && formik.errors.businessName ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="businessNameError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.businessName}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="Phone" className="agw-form-label agw-fw-semibold">
                {translations.phoneSignIn}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.Phone && (formik.errors.Phone || phoneBackendErrorMsg)
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="Phone"
                name="Phone"
                onChange={formik.handleChange}
                onBlur={handlePhoneBlur}
                value={formik.values.Phone}
                aria-required="true"
                aria-invalid={formik.touched.Phone && formik.errors.Phone ? true : false}
                aria-describedby="PhoneError"
              />
              {formik.touched.Phone && (formik.errors.Phone || phoneBackendErrorMsg) ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="PhoneError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.Phone ?? phoneBackendErrorMsg}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="Address"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.streetMyProfile}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.Address && formik.errors.Address
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="Address"
                name="Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Address}
                aria-required="true"
                aria-invalid={formik.touched.Address && formik.errors.Address ? true : false}
                aria-describedby="AddressError"
              />
              {formik.touched.Address && formik.errors.Address ? (
                <span
                  dangerouslySetInnerHTML={{ __html: formik.errors.Address }}
                  className="agw-d-block agw-mt-1 agw-text-negative"
                  id="AddressError"
                  aria-live="assertive" aria-atomic="true"
                ></span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="City" className="agw-form-label agw-fw-semibold">
                {translations.cityMyProfile}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.City && formik.errors.City
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="City"
                name="City"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.City}
                aria-required="true"
                aria-invalid={formik.touched.City && formik.errors.City ? true : false}
                aria-describedby="CityError"
              />
              {formik.touched.City && formik.errors.City ? (
                <span
                  className="agw-d-block agw-mt-1 agw-text-negative"
                  dangerouslySetInnerHTML={{ __html: formik.errors.City }}
                  id="CityError"
                  aria-live="assertive" aria-atomic="true"
                ></span>
              ) : null}
            </div>
            {stateVisible && citySelectItems.length > 0 ? (
              <div className="agw-col-12">
                <label htmlFor="BusinessState" className="agw-form-label">
                  {translations.stateMyProfile}
                  <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                    *
                  </span>
                </label>
                <select
                  id="Province"
                  className="agw-form-select"
                  name="Province"
                  value={formik.values.Province}
                  onChange={formik.handleChange}
                  aria-required="true"
                  aria-invalid={formik.touched.Province && formik.errors.Province ? true : false}
                  aria-describedby="ProvinceError"
                >
                  {citySelectItems.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            <div className="agw-col-12">
              <label
                htmlFor="ZipCode"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.zipMyProfile}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.ZipCode &&
                  (formik.errors.ZipCode || zipCodeBackendErrorMsg)
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="ZipCode"
                name="ZipCode"
                onChange={formik.handleChange}
                onBlur={handleZipCodeBlur}
                value={formik.values.ZipCode}
                aria-required="true"
                aria-invalid={formik.touched.ZipCode && formik.errors.ZipCode ? true : false}
                aria-describedby="ZipCodeError"
              />
              {formik.touched.ZipCode &&
                (formik.errors.ZipCode || zipCodeBackendErrorMsg) ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="ZipCodeError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.ZipCode ?? zipCodeBackendErrorMsg}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="CountryCode" className="agw-form-label">
                {translations.Region}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <select
                id="CountryCode"
                className={`agw-form-select ${formik.touched.CountryCode && formik.errors.CountryCode
                  ? "agw-border-negative"
                  : ""
                  }`}
                name="CountryCode"
                value={formik.values.CountryCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-required="true"
              >
                {countrySelectItems
                  .filter((values) => {
                    if (locale === "de-de") {
                      return values.value === "DE" || values.value === "AT";
                    } else if (locale === "be-fr") {
                      return values.value === "BE" || values.value === "LU";
                    } else {
                      return values.value.includes(formik.values.CountryCode);
                    }
                  })
                  .map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
              </select>
              {formik.touched.CountryCode && formik.errors.CountryCode ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="CountryCodeError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.CountryCode}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="LanguageCode" className="agw-form-label">
                {translations.languageMyProfile}
                <span className="agw-fw-semibold agw-text-negative agw-ml-1">
                  *
                </span>
              </label>
              <select
                id="LanguageCode"
                className={`agw-form-select ${formik.touched.LanguageCode && formik.errors.LanguageCode
                  ? "agw-border-negative"
                  : ""
                  }`}
                name="LanguageCode"
                value={formik.values.LanguageCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-required="true"
              >
                {languageSelectItems.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {formik.touched.LanguageCode && formik.errors.LanguageCode ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.LanguageCode}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12 agw-gy-5">
              <Recaptcha
                recaptchaRef={recaptchaRef}
                formik = {formik}
              />
              {formik.touched.Recaptcha && formik.errors.Recaptcha ? (
                <span className="agw-d-block agw-mt-1 agw-text-center agw-text-negative">
                  {formik.errors.Recaptcha}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12 agw-gy-5">
              <div className="agw-form-check">
                <input
                  type="checkbox"
                  className="agw-form-check-input"
                  id="BusinessPolicyCheckbox"
                  checked={isChecked}
                  onChange={handlePolicyChange}
                />
                <label
                  className="agw-form-check-label"
                  htmlFor="BusinessPolicyCheckbox"
                  dangerouslySetInnerHTML={{
                    __html: utils.replaceStrToNum(agreeToCreateAccount, locale),
                  }}
                ></label>
              </div>
            </div>
            <div className="agw-col-12">
              <div className="agw-form-check">
                <input
                  type="checkbox"
                  className="agw-form-check-input"
                  id="BusinessAgreeCheckbox"
                  name="ContactAgreement"
                  checked={formik.values.ContactAgreement}
                  onChange={formik.handleChange}
                />
                <label
                  className="agw-form-check-label"
                  htmlFor="BusinessAgreeCheckbox"
                >
                  {translations.agreeToSubscribe}
                </label>
              </div>
            </div>
            <div className="agw-col-12 agw-gy-5">
              {errorMsg ? (
                <p className="agw-text-negative agw-text-center">{errorMsg}</p>
              ) : null}
              <button
                type="submit"
                className="agw-btn agw-btn-block agw-btn-primary"
                disabled={!formik.isSubmitting && !isChecked && formik.errors}
                ref={triggerButtonRef}
              >
                {translations.SignUp}
              </button>
            </div>
          </div>
        </form>
      </div>
      <ActiveLightbox
        activeLightbox={activeLightbox}
        email={formik.values.Email}
        onClose={closeAllLightboxes}
        onOpenLightbox={openLightbox}
        triggerButtonRef={triggerButtonRef}
      />
    </Fragment>
  );
};

export default Business;
