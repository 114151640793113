export async function fetchApi(url, method = "GET", data = null) {
  const options = {
    method: method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);
  const responseData = await response.json();

  return responseData;
}

export function isNumber(value) {
  return isFinite(Number(value));
}

export const replaceStrToNum = (str, text) => {
  if (!str) return str;

  return str.replace("{0}", text);
};


export function getQueryParamIgnoreCase(queryParams, name) {
  for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
          return value;
      }
  }
  return null;
}

export function Base64ToUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str.replace(/-/g, '+').replace(/_/g, '/')), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}