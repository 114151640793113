import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Signup from "./pages/SignupLayout";
import Consumer from "./pages/ConsumerSignup";
import Business from "./pages/BusinessSignup";
import Federated from "./pages/FederatedSignUp";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordVerify from "./pages/ResetPasswordVerify";
import UpdatePassword from "./pages/UpdatePassword";
import UpdatePasswordComplete from "./pages/UpdatePasswordComplete";
import ExpirePassword from "./pages/ExpirePassword";
import ProspectActv from "./pages/ProspectActv";
import Layout from "./Layout";
import ErrorPage from "./pages/ErrorPage";
import Languages from "./pages/SelectLanguage";

const withLayout = (Component) => {
  return function LayoutComponent(props) {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:locale/account/sign-in" element={withLayout(Login)()} />
        <Route
          path="/:locale/account/languages"
          element={withLayout(Languages)()}
        />
        <Route
          path="/:locale/account/reset-password"
          element={withLayout(ResetPassword)()}
        />
        <Route
          path="/:locale/account/reset-password-verify"
          element={withLayout(ResetPasswordVerify)()}
        />
        <Route
          path="/:locale/account/update-password"
          element={withLayout(UpdatePassword)()}
        />
        <Route
          path="/:locale/account/update-password-complete"
          element={withLayout(UpdatePasswordComplete)()}
        />
        <Route
          path="/:locale/account/expire-password"
          element={withLayout(ExpirePassword)()}
        />
        <Route
          path="/:locale/account/ProspectActv"
          element={withLayout(ProspectActv)()}
        />
        <Route
          path="/:locale/account/federated-sign-up"
          element={withLayout(Federated)()}
        />
        <Route path="/:locale/account/*" element={withLayout(ErrorPage)()} />
        <Route path="/:locale/account" element={withLayout(Signup)()}>
          <Route path="/:locale/account/sign-up" element={<Consumer />} />
          <Route
            path="/:locale/account/business-sign-up"
            element={<Business />}
          />
        </Route>
        <Route path="/:locale/account/logout" element={withLayout(Logout)()} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
