import React, { useEffect } from "react";
import { useLoading } from "../contexts/LoadingProvider";
import * as utils from "../helpers/utilityHelpers";
import { useParams, useLocation } from "react-router-dom";

const Logout = () => {
  const locale = useParams().locale;
  const location = useLocation();
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    logout().then((redirectUrl) => {
      setIsLoading(true);
      window.location.href = redirectUrl;
    });
  }, []);

  const logout = async () => {
    try {
      setIsLoading(true);

      const queryParams = new URLSearchParams(location.search.toLowerCase());
      const clientId = queryParams.get("clientid") ?? "";
      const redirectUrl = queryParams.get("redirecturl") ?? "";
      const url = `${process.env.REACT_APP_URL}/${locale}/api/account/sign-out?clientId=${clientId}&RedirectUrl=${redirectUrl}`;
      const res = await utils.fetchApi(url);
      return res.redirectUrl;
    } catch (error) {
      console.error("Logout failed:", error);
      return "/";
    }
  };

  return null;
};

export default Logout;
