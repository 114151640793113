import React, { createContext, useState, useContext } from "react";

const PrevLinkContext = createContext();

export const usePrevLink = () => useContext(PrevLinkContext);

export const PrevLinkProvider = ({ children }) => {
  const [prevLink, setPrevLink] = useState("sign-in");

  const value = { prevLink, setPrevLink };

  return (
    <PrevLinkContext.Provider value={value}>
      {children}
    </PrevLinkContext.Provider>
  );
};
