import React, { useContext, useRef, useEffect } from "react";
import { TranslationContext } from "../../contexts/TranslationProvider";
import * as utils from "../../helpers/utilityHelpers";
import * as validationSchemas from "../../validation/validationSchemas";
import useFocusTrap from '../../hooks/useFocusTrap';
import { useFormik } from "formik";
import { useLoading } from "../../contexts/LoadingProvider";
import { useParams, useLocation, Link } from "react-router-dom";
import { usePWshow } from "../../hooks/useCustom";


// 密碼過期跳出提示更新密碼的畫面
export const EnterNewPassword = ({ isOpen, onClose, email, password  }) => {

  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  const lightboxRef = useRef(null);
  const { isLoading, setIsLoading } = useLoading();
  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);

  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      ConfirmNewPassword: "",
      OldPassword: "",
    },
    validationSchema: validationSchemas.enterNewPwdValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      try {
       
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/change-expired-pwd`;
        const res = await utils.fetchApi(url, "POST", values);
        
        if (res.success) {
          localStorage.locale = "";
          window.location.href = `${process.env.REACT_APP_URL}/${locale}/account/sign-in`;
        } else {         
          res.errors.forEach(async (error) => {
            await formik.setFieldError(`${error.id}`, error.errorMsg);
            const errorInput = document.getElementsByName(`${error.id}`)[0];
            if (errorInput) {
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });
  
  useEffect(() => {
    formik.setFieldValue("OldPassword", password);
  },[])


  useFocusTrap(lightboxRef);
  if (!isOpen) return null;

  return (
    <form
    className="agw-col-md-5 agw-mx-auto"
    onSubmit={formik.handleSubmit}
  >
    <div className="agw-lightbox agw-d-block show" role="dialog" ref={lightboxRef} aria-labelledby="enterNewPassword">
      <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-6">
        <div className="agw-bg-white agw-w-100">
          <div className="agw-container agw-py-6 agw-px-4">
            <div className="agw-row agw-gy-3">
              <div className="agw-col-12">
                <h5 className="agw-text-center agw-fs-title-l agw-mb-0" id="enterNewPassword">
                  {translations.EnterNewPassword}
                </h5>
              </div>
              
              <div className="agw-col-12">
                <label
                  htmlFor="NewPassword"
                  className="agw-form-label agw-fw-semibold"
                >
                  {translations["New Password"]}
                </label>
                <div className="agw-po-relative">
                <input
                  type={isPwShow ? "text" : "password"}
                  className="agw-form-control"
                  id="NewPassword"
                  name="NewPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.NewPassword}
                  onPaste={(e) => e.preventDefault()}
                  aria-required="true"
                  aria-invalid={formik.touched.NewPassword && formik.errors.NewPassword ? true : false}
                  aria-describedby="NewPasswordError"
                />
                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handlePwShow(e); }}
                  aria-label={`${isPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <svg
                    id="icon-visibility-off"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}
                    role="img"
                  >
                    <title>{translations.hidePassword = 'undefined' ? 'Hide Password' : translations.hidePassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-off"
                      d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                    />
                  </svg>
                  <svg
                    id="icon-visibility-on"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}
                    role="img"
                  >
                    <title>{translations.showPassword = 'undefined' ? 'Show Password' : translations.showPassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-on"
                      d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                    />
                  </svg>
                </button>
                </div>
                 {formik.touched.NewPassword && formik.errors.NewPassword ? (
                <span
                  className="agw-d-block agw-mt-1 agw-text-negative"
                  id="NewPasswordError"
                  aria-live="assertive" aria-atomic="true"
                >
                  {formik.errors.NewPassword}
                </span>
                 ) : null}
              </div>
              <div className="agw-col-12">
                <label
                  htmlFor="ConfirmNewPassword"
                  className="agw-form-label agw-fw-semibold"
                >
                  {translations.ConfirmPassword}
                </label>
                <div className="agw-po-relative">
                <input
                  type={isConfirmPwShow ? "text" : "password"}
                  className="agw-form-control"
                  id="ConfirmNewPassword"
                  name="ConfirmNewPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ConfirmNewPassword}
                  onPaste={(e) => e.preventDefault()}
                  aria-required="true"
                  aria-invalid={formik.touched.ConfirmNewPassword && formik.errors.ConfirmNewPassword ? true : false}
                  aria-describedby="ConfirmNewPasswordError"
                />

                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pr-2"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handleConfirmPwShow(e); }}
                  aria-label={`${isConfirmPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <svg
                    id="icon-visibility-off"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isConfirmPwShow ? "agw-d-none" : ""}`}
                    role="img"
                  >
                    <title>{translations.hidePassword = 'undefined' ? 'Hide Password' : translations.hidePassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-off"
                      d="m22,12c-.64,1.62-1.66,3.07-2.94,4.23l-2.41-2.41c.22-.57.35-1.18.35-1.82,0-2.76-2.24-5-5-5-.64,0-1.25.13-1.82.35l-1.71-1.71c1.11-.41,2.29-.64,3.53-.64,4.55,0,8.43,3.01,10,7Zm-4.56,5.44l1.15,1.15-1.41,1.41-1.65-1.65c-1.11.41-2.28.65-3.52.65-4.55,0-8.43-3.01-10-7,.64-1.62,1.66-3.07,2.94-4.23l-2.36-2.36,1.41-1.41,2.56,2.56,1.91,1.91,1.42,1.41,4.24,4.24,1.42,1.41,1.91,1.91Zm-3.62-.8l-1.66-1.66c-.05,0-.1.02-.16.02-1.66,0-3-1.34-3-3,0-.05.01-.1.02-.16l-1.66-1.66c-.22.57-.35,1.18-.35,1.82,0,2.76,2.24,5,5,5,.64,0,1.25-.13,1.82-.35Zm1.18-4.65c0-1.66-1.34-3-3-3-.05,0-.1.01-.16.02l3.14,3.14c0-.05.02-.1.02-.16Z"
                    />
                  </svg>
                  <svg
                    id="icon-visibility-on"
                    viewBox="0 0 24 24"
                    className={`icons_24 agw-pe-none ${isConfirmPwShow ? "" : "agw-d-none"}`}
                    role="img"
                  >
                    <title>{translations.showPassword = 'undefined' ? 'Show Password' : translations.showPassword}</title>
                    <rect id="mask" width="24" height="24" fill="none" />
                    <path
                      id="visibility-on"
                      d="m12,5c-4.55,0-8.43,3.01-10,7,1.57,3.99,5.45,7,10,7s8.43-3.01,10-7c-1.57-3.99-5.45-7-10-7Zm0,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm3-5c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z"
                    />
                  </svg>
                </button>
                </div>
                 {formik.touched.ConfirmNewPassword &&
                formik.errors.ConfirmNewPassword ? (
                <span className="agw-d-block agw-mt-1 agw-text-negative"
                  id="ConfirmNewPasswordError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.ConfirmNewPassword}
                </span>
              ) : null}
              </div>
              <div className="agw-col-12 agw-gy-5 agw-text-center">
                <button type="submit" className="agw-btn agw-btn-primary"
                 disabled={!formik.values.NewPassword && !formik.values.ConfirmPassword}>
                  {translations["Change Password"]}
                </button>
                <button
                  type="button"
                  className="agw-btn agw-btn-tertiary-black agw-mx-3"
                  onClick={onClose}
                >
                  {translations.Cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  );
};
