import React, { useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import HeadlineContext from "contexts/HeadlineProvider";
import { Outlet, Link } from "react-router-dom";

function Signup() {
  const translations = useContext(TranslationContext);
  const location = useLocation();
  const locale = useParams().locale;
  const currentSearch = location.search;
  const url = location.pathname.split("/");
  const tabUrl = url[url.length - 1];
  const [headLine, setHeadLine] = useState("");
  const [b2BsignUpTabVisible, setB2BsignUpTabVisible] = useState("");

  const value = {
    setHeadLine,
    setB2BsignUpTabVisible,
  };

  return (
    <HeadlineContext.Provider value={value}>
      <div className="agw-mb-5">
        <h1 className="agw-fw-semibold agw-fs-headline-l agw-text-center">
          {headLine}
        </h1>
      </div>
      {!b2BsignUpTabVisible ? (
        ""
      ) : (
        <>
          <nav className="agw-mb-5">
            <div className="agw-nav agw-nav-tabs agw-justify-content-center">
              <Link
                tabIndex="0"
                className={`agw-nav-link ${
                  tabUrl === "sign-up" ? "is-active" : ""
                }`}
                to={`/${locale}/account/sign-up${currentSearch}`}
              >
                {translations.Consumer}
              </Link>
              <Link
                tabIndex="0"
                className={`agw-nav-link ${
                  tabUrl === "business-sign-up" ? "is-active" : ""
                }`}
                to={`/${locale}/account/business-sign-up${currentSearch}`}
              >
                {translations.Business}
              </Link>
            </div>
          </nav>
        </>
      )}
      <Outlet />
    </HeadlineContext.Provider>
  );
}

export default Signup;
