import React, { useContext } from "react";
import { TranslationContext } from "contexts/TranslationProvider";

function MediaBtn({ state, codeChallenge }) {
  const translations = useContext(TranslationContext);
  const handleSocialLogin = (social) => {
    const url = `${process.env.REACT_APP_IDPHUB}/oauth2/authorize?redirect_uri=${process.env.REACT_APP_URL}/account/callback&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&identity_provider=${social}&scope=aws.cognito.signin.user.admin email openid phone profile&state=${state}`;
    window.location.href = url;
  };

  return (
    <div className="agw-col-md-5 agw-mx-auto">
      <div className="agw-row agw-gy-3">
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-border agw-border-600"
            onClick={() => handleSocialLogin("Google")}
            role="link"
          >
            <svg
              viewBox="0 0 24 24"
              id="google-plus"
              className="icons_20 agw-mr-2"
            >
              <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path
                  fill="#4285F4"
                  d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                />
                <path
                  fill="#34A853"
                  d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                />
                <path
                  fill="#FBBC05"
                  d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                />
                <path
                  fill="#EA4335"
                  d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                />
              </g>
            </svg>
            <span className="agw-fs-body-l agw-text-600 agw-fw-semibold">
              {translations.GoogleSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-border agw-border-600"
            onClick={() => handleSocialLogin("Microsoft")}
            role="link"
          >
            <svg id="agw-icon-windows-color"  className="icons_20 agw-mr-2" viewBox="0 0 24 24">
              <rect id="mask" width="24" height="24" fill="none"/><g id="windows-color"><path id="red" d="M2,2h9.52v9.52H2V2Z" fill="#f35325"/><path id="green" d="M12.48,2h9.52v9.52h-9.52V2Z" fill="#81bc06"/><path id="blue" d="M2,12.48h9.52v9.52H2v-9.52Z" fill="#05a6f0"/><path id="yellow" d="M12.48,12.48h9.52v9.52h-9.52v-9.52Z" fill="#ffba08"/></g></svg>
            <span className="agw-fs-body-l agw-text-600 agw-fw-semibold">
              {translations.MicrosoftSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center"
            style={{ backgroundColor: "#4267B2" }}
            onClick={() => handleSocialLogin("Facebook")}
            role="link"
          >
            <svg
              id="facebbok"
              className="icons_20 agw-mr-2"
              viewBox="0 0 24 24"
            >
              <path
                id="facebook"
                fill="#fff"
                d="M13.92,23.99c0-0.05,0-0.11,0-0.16c0-2.72,0-5.44,0-8.16c0-0.14,0.04-0.18,0.18-0.18c0.82,0,1.63,0,2.45,0
            c0.23,0,0.23-0.01,0.26-0.24c0.06-0.45,0.14-0.89,0.21-1.34c0.09-0.59,0.19-1.18,0.28-1.77c0-0.02,0-0.05,0.01-0.09
            c-1.13,0-2.25,0-3.38,0c0-0.05,0-0.08,0-0.11c0.01-0.73,0.01-1.47,0.03-2.2c0.01-0.21,0.04-0.43,0.1-0.63
            c0.18-0.6,0.6-0.93,1.2-1.05c0.2-0.04,0.4-0.06,0.6-0.06C16.32,8,16.8,8,17.29,8c0-0.08,0.01-0.14,0.01-0.21c0-0.87,0-1.75,0-2.62
            c0-0.25,0-0.26-0.24-0.28c-0.8-0.08-1.6-0.19-2.41-0.21c-0.9-0.02-1.78,0.16-2.57,0.63c-1.02,0.61-1.62,1.52-1.88,2.67
            c-0.13,0.54-0.17,1.1-0.17,1.65c0,0.76,0,1.53,0,2.29c0,0.12-0.05,0.14-0.15,0.14c-0.92,0-1.84,0-2.76,0c-0.06,0-0.11,0-0.2,0
            c0,0.08,0,0.14,0,0.21c0,1.03,0.01,2.06,0,3.09C6.93,15.5,7,15.5,7.1,15.5c0.89,0,1.79-0.01,2.68,0c0.33,0,0.26-0.01,0.26,0.26
            c0,2.71,0,5.41,0,8.11c0,0.04-0.01,0.07-0.01,0.13c-2.21-0.38-4.18-1.29-5.86-2.77c-2.42-2.13-3.83-4.82-4.12-8.04
            C-0.57,6.17,4.69,0.5,11.09,0.03C18-0.47,23.69,4.87,23.99,11.47C24.3,18.4,19.13,23.24,13.92,23.99z"
              />
            </svg>
            <span className="agw-fs-body-l agw-text-white agw-fw-semibold">
              {translations.FacebookSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-bg-black"
            onClick={() => handleSocialLogin("Twitter")}
            role="link"
          >
            <svg
              id="icon-twitter-x"
              viewBox="0 0 24 24"
              className="icons_20 agw-mr-2"
            >
              <rect
                id="mask"
                width="24"
                height="24"
                fill="none"
                strokeWidth="0"
              />
              <path
                id="twitter-x"
                fill="#fff"
                d="m13.86,10.47l7.3-8.47h-1.73l-6.34,7.35-5.05-7.35H2.2l7.65,11.12-7.65,8.88h1.73l6.69-7.77,5.34,7.77h5.84l-7.94-11.53h0Zm-2.37,2.75l-.78-1.11L4.55,3.3h2.66l4.98,7.11.78,1.11,6.47,9.24h-2.66l-5.28-7.54h-.01Z"
                strokeWidth="0"
              />
            </svg>
            <span className="agw-fs-body-l agw-text-white agw-fw-semibold">
              {translations.TwitterSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-bg-black"
            onClick={() => handleSocialLogin("SignInWithApple")}
            role="link"
          >
            <svg
              id="icon-apple"
              viewBox="0 0 24 24"
              className="icons_20 agw-mr-2"
            >
              <rect id="mask" width="24" height="24" fill="none" />
              <path
                id="apple"
                fill="#fff"
                d="m19.51,8.8c-1.32.83-2.14,2.24-2.14,3.82,0,1.74,1.07,3.32,2.64,4.07-.33,1-.74,1.99-1.32,2.82-.82,1.24-1.73,2.49-3.05,2.49s-1.73-.91-3.22-.91-2.06.83-3.3.83-2.06-1.16-3.05-2.49c-1.32-1.99-2.06-4.23-2.06-6.56,0-3.9,2.47-5.89,4.95-5.89,1.32,0,2.39.83,3.22.83.74,0,1.98-.91,3.46-.91,1.57-.08,3.05.58,3.88,1.91Zm-4.62-3.65c.66-.75.99-1.74,1.07-2.74v-.41c-1.15.08-2.14.66-2.89,1.49-.66.75-1.07,1.66-1.07,2.74v.33h.25c1.07-.08,1.98-.58 ,2.64-1.41Z"
              />
            </svg>
            <span className="agw-fs-body-l agw-text-white agw-fw-semibold">
              {translations.AppleSocialMediaText}
            </span>
          </button>
        </div>
       
      </div>
    </div>
  );
}

export default MediaBtn;
