import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import { TranslationContext } from 'contexts/TranslationProvider';

function ExpirePassword() {
  var url ="";
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  if(typeof translations.ResetPwdExpired !== 'undefined')
  {
    url = translations.ResetPwdExpired.replace("{0}", locale);
  }
  return (
    <div className="agw-pt-6" aria-live="assertive" aria-atomic="true">
      <div className="agw-col-md-5 agw-mx-auto agw-fs-body-l agw-text-center" dangerouslySetInnerHTML={{ __html: url }}></div>
    </div>
  )
}

export default ExpirePassword;